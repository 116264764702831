.App {
  margin: 20px;
  text-align: left;
}

.hotel-block > h1 {
  font-size: 2em;
  text-align: center;
  margin: 1em 1em 0.5em 1em;
}

.address-block {
  font-size: 1em;
  text-align: center;
}

.rezgatehotelid-block {
  font-size: 1em;
  text-align: center;
  font-weight: bold;
}

.address-block p {
  margin: 0.5em;
}

.main-block {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
}

.main-block > section {
  margin: 10px 15px;
}

.left-block {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  max-width: 60%;
  min-width: 400px;
}

.left-block img {
  height: 250px;
  object-fit: cover;
}

.right-block {
  display: flex;    
  flex-direction: column;
  flex-wrap: wrap;
  max-width: 60%;
}


.main-block h1, .main-block h2, .main-block h3 {
  font-weight: bold;
}